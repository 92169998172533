import { template as template_7aab69c3da0241d98521cd3bfe8687af } from "@ember/template-compiler";
const WelcomeHeader = template_7aab69c3da0241d98521cd3bfe8687af(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
